import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import FAQs from "./pages/FAQs";


const App = () => {
  
  return (
    <Routes>
      <Route exact path="/" element={<Home/>}></Route>
      <Route exact path="/about" element={<About/>}></Route>
      <Route exact path="/faqs" element={<FAQs/>}></Route>
    </Routes>
  )
}

export default App;


//main color - #0C175E
//secondary color - #EDEFF5 - greyish white
// faint text - rgba(0, 0, 0, 0.54)
// body text - rgba(16, 16, 16, 1)
