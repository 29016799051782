import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react/';
import chevrons from '../../assets/chevrons.svg';
import close from '../../assets/close.svg'
import { ContextDestructured } from '../../context/AppContext';

function Calculator() {

    const {cardRates, calculatorVisible, setCalculatorVisible} = ContextDestructured();
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [amount, setAmount] = useState(0);
    const [cardsShown, setCardsShown] = useState(false);
    const [countryShown, setCountryShown] = useState(false);
    const [cardTypeShown, setCardTypeShown] = useState(false);

    const formatNumber = (number) => {
      let parts = number.split('.');
      let numberPart = parts[0];
      let decimal = parts[1];
      let regex = /\B(?=(\d{3})+(?!\d))/g
      return numberPart.replace(regex, ',') + (decimal ? '.'+decimal : '')
    }

    useEffect(() => {
      console.log(cardRates[selectedIndex]?.countries[selectedCountry]?.types[selectedType])
    }, [selectedIndex,selectedType,selectedCountry]);

  return (
    
    <section id="calculator-app" className={`${calculatorVisible? 'opacity-1 scale-100' : 'scale-0 opacity-0'} transition duration-300 z-20 font-inter font-light flex justify-center items-center m-0 fixed top-0 bottom-0 left-0 right-0 bg-[#eeeeee]`}>
        <section 
          className="w-4/5 md:w-4/5 lg:w-3/5 bg-white px-8 md:px-16 lg:px-24
          py-16 lg:py-24 xl:py-32 
          flex flex-col md:flex-row items-center justify-center rounded-md shadow-lg">
            {/*Close Icon*/}
            <section onClick = {ev => setCalculatorVisible(!calculatorVisible)} className="absolute top-6 right-6 md:right-12 md:top-12 p-3 rounded-full z-3 cursor-pointer">
              <img className="w-3 h-3 md:w-4 md:h-4 hover:scale-125 hover:rotate-45 transition duration-150" src={close} alt="close icon" />
            </section>

            {/*Input Controls*/}
            <section>
              <h3 className="text-center md:text-left font-inter font-bold text-maroon text-xl lg:text-2xl">
               Rates Calculator
              </h3>
              <p className="font-regular text-faint text-md text-center md:text-left">Know the curent value of your transaction !</p>

              <section className="mt-12">
                {/*For the Gift Cards*/}
                <section onClick = {ev => setCardsShown(!cardsShown)} className=" relative flex justify-between py-4 px-3 border-[#eee] border rounded-sm cursor-pointer hover:bg-[#efefef]">
                  <p
                  className="text-faint text-sm lg:text-md">{(selectedIndex === null) ? 'Select Your Card': cardRates[selectedIndex].name}</p>
                  <section className="flex items-center justify-center">
                    <img className="w-3 h-3" src={chevrons} alt="chevrons" />
                  </section>

                  <section className={`${!cardsShown? 'hidden': ''} w-full left-0 top-0
                  bg-white z-30 overflow-y-scroll shadow-sm absolute space-y-4`}>
                    {cardRates.map((card, index) => 
                      <p 
                      onClick = {ev => {setSelectedIndex(index); setCardsShown(!cardsShown)}}
                      key={card.name} 
                      className="text-sm md:text-md font-light hover:bg-[#eee] py-2 px-4 ">
                        {`${(card.name.length > 30) ? `${card.name.substring(0,30)}...`: `${card.name}` }`}
                      </p>
                    )}
                  </section>
                </section>

                {/*For The Countries*/}
                <section onClick = {ev => setCountryShown(!countryShown)} 
                className="mt-6 relative flex justify-between py-4 px-3 border-[#eee] border rounded-sm 
                cursor-pointer hover:bg-[#efefef]">
                  <p
                  className="text-faint text-sm lg:text-md">
                    {(selectedCountry == null) ? 'Select Your Country': 
                    <p className="flex space-x-4 items-center">
                      <span className={`fi fi-${cardRates[selectedIndex].countries[selectedCountry].flag}`}></span>
                      <span>{cardRates[selectedIndex].countries[selectedCountry].name}</span>
                    </p>}
                  </p>
                  <section className="flex items-center justify-center">
                    <img className="w-3 h-3" src={chevrons} alt="chevrons" />
                  </section>

                  <section className={`${!countryShown? 'hidden': ''} w-full left-0 top-0
                  bg-white z-30 overflow-y-scroll shadow-sm absolute space-y-4`}>
                    {(selectedIndex !== null) && cardRates[selectedIndex]['countries'].map((country, index) => 
                      <p 
                      onClick = {ev => {setSelectedCountry(index); setCountryShown(!countryShown)}}
                      key={country.name} 
                      className="text-sm md:text-md font-light hover:bg-[#eee] py-2 px-4 flex space-x-3 items-center">
                        <span className={`fi-${country.flag} fi`}></span>
                        <span>{`${(country.name.length > 30) ? `${country.name.substring(0,30)}...`: `${country.name}` }`}</span>
                      </p>
                    )}
                  </section>
                </section>

                {/*For the Card Type*/}
                <section onClick = {ev => setCardTypeShown(!cardTypeShown)} 
                className="mt-6 relative flex justify-between py-4 px-3 border-[#eee] border rounded-sm cursor-pointer hover:bg-[#efefef]">
                  <p
                  className="text-faint text-sm lg:text-md">
                    { (selectedType === null) ? 'Select Card Type': cardRates[selectedIndex].countries[selectedCountry].types[selectedType].name}
                  </p>
                  <section className="flex items-center justify-center">
                    <img className="w-3 h-3" src={chevrons} alt="chevrons" />
                  </section>

                  <section className={`${!cardTypeShown? 'hidden': ''} w-full left-0 top-[100]
                  bg-white z-30 overflow-y-scroll shadow-sm absolute space-y-4`}>
                    {(selectedCountry !== null) && cardRates[selectedIndex]['countries'][selectedCountry].types.map((type, index) => 
                      <p 
                      onClick = {ev => {setSelectedType(index); setCountryShown(false); setCardTypeShown(!cardTypeShown)}}
                      key={type.name} 
                      className="text-sm md:text-md font-light hover:bg-[#eee] py-4 px-4 ">
                        {`${(type.name.length > 30) ? `${type.name.substring(0,30)}...`: `${type.name}` }`}
                      </p>
                    )}
                  </section>
                </section>

                {/*For the Amount*/}
                <section className="mt-6">
                  <section>
                    <input min={0}
                    onChange = {ev => {setAmount(ev.target.value)}}
                    className="w-full py-4 px-3 font-inter border border-[#eee] outline-0" placeholder="Enter amount" type="number" />
                  </section>
                </section>
              </section>
            </section>

            {/*Rates Display*/}
            <section className=" flex-1 min-w-[40%] ml-0 md:ml-16 mt-6 md:mt-0 flex flex-col justify-center items-center">
              <p className="text-faint text-lg font-inter font-medium">Total Payout</p>
              <h3 className="mt-6 font-bold font-inter text-2xl text-maroon">
                ₦ {formatNumber(`${cardRates[selectedIndex]?.['countries'][selectedCountry]?.['types'][selectedType]?.rate * amount}`)}
              </h3>
              <p className="mt-4">Rate: NGN {cardRates[selectedIndex]?.['countries'][selectedCountry]?.['types'][selectedType]?.rate}</p>
            </section>
        </section>
    </section>
  )
}


export default Calculator;
