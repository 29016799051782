import React from 'react';
import {useState} from 'react';
import showLess from '../../assets/showLess.svg';
import showMore from '../../assets/showMore.svg';

const Faq = ({question}) => {
  const [expanded, setExpanded] = useState(false);
    
  return (
    <section className="font-inter border border-[#eee] rounded-md bg-white w-full lg:w-4/6">
      <section onClick={ev => setExpanded(!expanded)} className={`${expanded ? 'bg-[#eee]' : ''} cursor-pointer flex justify-between hover:bg-[#eee] px-8 py-8`}>
        <h3 className="text-sm md:text-lg lg:text-xl font-medium text-faint leading-6">{question.question}</h3>
        <div className="flex justify-center items-center">
          <img className="w-0 h-0 md:w-4 md:h-4" src={!expanded ? showMore: showLess} alt="" />
        </div>
      </section>

      <section className={`py-5 px-8 transition duration-300 ease-in ${!expanded ? 'hidden': 'block'}`}>
        <p className="text-[#555] font-normal text-faint text-md leading-8 text-sm md:text-lg md:leading-8">{question.answer}</p>
      </section>
    </section>
  )
}

export default Faq;