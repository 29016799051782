import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import './styles.css';
import {BrowserRouter, Link} from 'react-router-dom';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import {ContextProvider} from './context/AppContext';

ReactDOM.render(
        <ContextProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ContextProvider>,

    document.getElementById('root')
)

