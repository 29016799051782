import { useContext, createContext, useState } from "react";



let AppContext = createContext();
export const ContextProvider = ({children}) => {
    const [userName, setUserName] = useState('John Doe');
    const [openNav, setOpenNav] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const [chatLink] = useState('https://wa.me/message/XF6HKCPMQO7WI1');
    const [instaLink] = useState('https://instagram.com/blanqchequez_exchange/');
    const [cardRates] = useState([
        {
            name: 'Xbox Gift Card',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Gift Card', rate: 400}]
                },
                {
                    name: 'Canada',
                    flag: 'ca',
                    types: [{name: 'Gift Card', rate: 200}]
                },
                {
                    name: 'Euro',
                    flag: 'eu',
                    types: [{name: 'Gift Card', rate: 270}]
                },
                {
                    name: 'Australia',
                    flag: 'au',
                    types: [{name: 'Gift Card', rate: 250}]
                },
                {
                    name: 'UK',
                    flag: 'gb',
                    types: [{name: 'Physical Card', rate: 350}]
                },
                {
                    name: 'Singapore',
                    flag: 'sg',
                    types: [{name: 'Physical Card', rate: 170}]
                },
            ]
        },

        {
            name: 'Razer Gold',
            countries: [
                {
                    name: 'Canada',
                    flag: 'ca',
                    types: [{name: 'Physical Card', rate: 330}]
                },
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Gift Card', rate: 540}]
                },
                {
                    name: 'Singapore',
                    flag: 'sg', 
                    types: [{name: 'Physical Card', rate: 310}]
                },
            ]
        },

        {
            name: 'ITUNES/Apple Gift Card',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 440}, {name: 'E-Code', rate: 420}]
                },

                {
                    name: 'Euro',
                    flag: 'eu',
                    types: [{name: 'Physical Card', rate: 310}]
                },

                {
                    name: 'Australia',
                    flag: 'au',
                    types: [{name: 'Physical Card', rate: 210}]
                },
                {
                    name: 'Canada',
                    flag: 'ca',
                    types: [{name: 'Physical Card', rate: 270}, {name: 'E-Code', rate: 260}]
                },
                {
                    name: 'UK',
                    flag: 'gb',
                    types: [{name: 'Physical Card', rate: 320}, {name: 'E-Code', rate: 240}]
                },
                {
                    name: 'Switzerland',
                    flag: 'ch',
                    types: [{name: 'Physical Card', rate: 360}, {name: 'E-Code', rate: 350}]
                },
                {
                    name: 'New Zealand',
                    flag: 'nz',
                    types: [{name: 'Physical Card', rate: 220}]
                },
                {
                    name: 'Germany',
                    flag: 'de',
                    types: [{name: 'Physical Card', rate: 260}, {name: 'E-Code', rate: 250}]
                },
                {
                    name: 'Netherlands',
                    flag: 'nl',
                    types: [{name: 'Physical Card', rate: 310}, {name: 'E-Code', rate: 290}]
                },
                {
                    name: 'Singapore',
                    flag: 'sg',
                    types: [{name: 'Physical Card', rate: 350}]
                },
                {
                    name: 'Italy',
                    flag: 'it',
                    types: [{name: 'Physical Card', rate: 260}]
                },
                {
                    name: 'Spain',
                    flag: 'es',
                    types: [{name: 'Physical Card', rate: 340}]
                },
                {
                    name: 'Finland',
                    flag: 'fi',
                    types: [{name: 'Physical Card', rate: 500}]
                },
                {
                    name: 'Austria',
                    flag: 'at',
                    types: [{name: 'Physical Card', rate: 450}]
                },

                {
                    name: 'France',
                    flag: 'fr',
                    types: [{name: 'Physical Card', rate: 340}]
                },
                {
                    name: 'Belgium',
                    flag: 'be',
                    types: [{name: 'Physical Card', rate: 260}]
                },
            ]
        },

        {
            name: 'Google Play',
            countries: [
                {
                    name: 'USA',
                    types: [{name: 'Physical Card', rate: 400}, {name: 'E-Code', rate: 390}]
                },
                {
                    name: 'UK',
                    flag: 'gb', 
                    types: [{name: 'Physical Card', rate: 470}, {name: 'E-Code', rate: 460}]
                },
                {
                    name: 'Euro',
                    flag: 'eu',
                    types: [{name: 'Physical Card', rate: 410}, {name: 'E-Code', rate: 380}]
                },
                {
                    name: 'Australia',
                    flag: 'au',
                    types: [{name: 'Physical Card', rate: 210}]
                },
                {
                    name: 'Canada',
                    flag: 'ca',
                    types: [{name: 'Physical Card', rate: 310}, {name: 'E-Code', rate: 300}]
                },
                {
                    name: 'Switzerland',
                    flag: 'ch',
                    types: [{name: 'Physical Card', rate: 390}]
                },
                {
                    name: 'Japan',
                    flag: 'jp',
                    types: [{name: 'Physical Card', rate: 2}]
                },
                {
                    name: 'New Zealand',
                    flag: 'nz',
                    types: [{name: 'Physical Card', rate: 240}]
                },
            ]
        },

        {
            name: 'Steam',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 490}, {name: 'E-Code', rate: 470}]
                },
                {
                    name: 'Canada',
                    flag: 'ca',
                    types: [{name: 'Physical Card', rate: 340}, {name: 'E-Code', rate: 330}]
                },
                {
                    name: 'Australia',
                    flag: 'au',
                    types: [{name: 'Physical Card', rate: 300}, {name: 'E-Code', rate: 280}]
                },
                {
                    name: 'UK',
                    flag: 'gb',
                    types: [{name: 'Physical Card', rate: 540}, {name: 'E-Code', rate: 520}]
                },
                {
                    name: 'Euro',
                    flag: 'eu',
                    types: [{name: 'Physical Card', rate: 470}, {name: 'E-Code', rate: 450}]
                },
                {
                    name: 'New Zealand',
                    flag: 'nz',
                    types: [{name: 'Physical Card', rate: 270}]
                },
                {
                    name: 'Switzerland',
                    flag: 'ch',
                    types: [{name: 'Physical Card', rate: 490}]
                },
            ]
        },

        {
            name: 'BestBuy',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 320}]
                },
            ]
        },
        {
            name: 'Visa Gift Card 4034/4358/4912/master(5113)',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 820}]
                },
            ]
        },

        {
            name: 'Macy',
            countries: [
                {
                    name: 'USA',
                    types: [{name: 'Physical Card', rate: 350}]
                },
            ]
        },
        {
            name: 'Nordstorm',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 400}]
                },
            ]
        },
        {
            name: 'Sephora',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 320}]
                },
            ]
        },

        {
            name: 'American Express 3779',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 390}]
                },
            ]
        },

        {
            name: 'Walmart Visa (4852/4786)',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 540}]
                },
            ]
        },
        
        {
            name: 'Vanilla(5432,5164,4847,4941,4142,4118,5253,4097)',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 410}]
                },
            ]
        },

        {
            name: 'eBay Gift Card',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 380}]
                },
            ]
        },

        {
            name: 'Amazon card( We Accept AQ Number Only)',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Cash Receipt', rate: 420}, {name: 'No Receipt', rate: 330}]
                },
            ]
        },

        {
            name: 'Nike Gift Card',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 330}]
                },
            ]
        },

        {
            name: 'Target Gift Card',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 430}]
                },
            ]
        },
        
        {
            name: 'Footlocker',
            countries: [
                {
                    name: 'USA',
                    flag: 'us',
                    types: [{name: 'Physical Card', rate: 490}]
                },
            ]
        },
    ]);
    const [calculatorVisible, setCalculatorVisible] = useState(false);
    

    return (
        <AppContext.Provider value={{
            openNav, setOpenNav, darkMode, setDarkMode, 
            userName, chatLink, instaLink, cardRates, calculatorVisible, setCalculatorVisible
        }}>
            {children}
        </AppContext.Provider>
    )
}


export const ContextDestructured = () => useContext(AppContext);
