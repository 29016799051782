import React from 'react';
import instagram from '../../assets/instagram.svg';
import twitter from '../../assets/twitter.svg';
import linkedin from '../../assets/linkedin.svg';
import facebook from '../../assets/facebook.svg';
import location from '../../assets/locations.svg';
import call from '../../assets/call.svg';
import { NavLink } from 'react-router-dom';
import mail from '../../assets/mail.svg';
import chevrons from '../../assets/chevrons-right.svg';
import { ContextDestructured } from '../../context/AppContext';


const Footer = ({inverse}) => {

  const mediaIcons = [
    {icon: facebook, link: 'https://facebook.com/blanqchequezexchange'}, 
    {icon: instagram, link: 'https://instagram.com/blanqchequez_exchange/'}, 
    {icon: twitter, link: 'https://twitter.com/BlanqchequezE'},
  ];
  let {instaLink} = ContextDestructured();


  return (
    <>
    <footer className={`px-8 sm:px-20 md:px-24 lg:px-32 py-8 sm:py-16 lg:py-32 flex 
    lg:space-x-12 xl:space-x-14 space-y-8 lg:space-y-0
    flex-col md:flex-row flex-wrap md:justify-between lg:justify-center lg:items-center items-start md:space-x-8 space-x-0
    ${inverse ? 'bg-transparent text-white': 'bg-white text-maroon'}`}>
      <section>
        <h3 className={`${inverse ? 'text-white' : 'text-maroon'} text-xl md:text-2xl font-inter font-bold`}>BlanqChequez <br /> Exchange</h3>
        <section className="flex space-x-4 mt-6">
          {mediaIcons.map((icon, index) => 
            <section key={index} className="p-2 rounded-full bg-white">
              <a target="_blank" href={`${icon.link}`}>
                <img className="w-4 h-4" src={icon.icon} alt={index} />
              </a>
            </section>
          )}
        </section>
      </section>

      <section className="">
        <h4 className="font-sans font-bold text-xl md:text-2xl">Useful Links</h4>
        <section className="font-sans flex flex-col spacey-y-4 mt-6">
          <p className="text-md lg:text-lg"><NavLink to='/'>Home</NavLink></p>
          <p className="text-md lg:text-lg"><a href="/about">About</a></p>
          <p className="text-md lg:text-lg"><a href="/faqs">FAQs</a></p>
        </section>
      </section>

      <section className="">
        <h3 className="font-sans font-bold text-xl md:text-2xl">Contacts</h3>
        <section className="flex flex-col space-y-3 mt-6">
          <section className="flex items-center justify-start space-x-2 md:space-x-3">
            <img className="w-5 h-5" src={location} alt="location icon" />
            <p className="text-md lg:text-lg">Suite 22 Bash Karma Plaza, Kubwa Abuja</p>
          </section>

          <section className="flex items-center justify-start space-x-2 md:space-x-3  mt-6">
            <img className="w-4 h-4" src={call} alt="telephone icon" />
            <p>+234 806 660 0343</p>
          </section>
          <section className="flex items-center justify-start space-x-2 md:space-x-3 mt-6">
            <img className="w-4 h-4" src={mail} alt="mail icon" />
            <p className="text-md lg:text-lg"><a href="mailto:blanqchequezexchange@gmail.com">blanqchequezexchange@gmail.com</a></p>
          </section>
        </section>
      </section>
    </footer>
    <section className="flex flex-col items-center justify-center py-8 px-8 sm:px-16 md:px-20 lg:px-32 text-white">
      <section className="pb-8 border-t border-white h-[2px] w-4/5"></section>
      <p className="font-sans font-light text-md lg:text-xl">
        Copyright 2022, BlanqChequez Exchange. All rights reserved.
      </p>
    </section>
    </>
  )
}

export default Footer;

