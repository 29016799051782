import {useState} from 'react';
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';
import MobileNav from '../components/mobileNav/MobileNav';

function About() {
  return (
    <section>

      <section>
        <Navbar/>
        <MobileNav/>
      </section>


      {/*About Section*/}
      <section id="about">
        <section className="px-12 sm:px-20 md:px-24 lg:px-48 py-16 lg:py-32">
          <h1 className="text-center text-xl md:text-3xl lg:text-4xl font-inter font-bold text-maroon">Who We Are</h1>
          <section className="flex justify-center">
            <p className="w-full md:w-4/5 lg:w-3/5 text-center mt-6 lg:leading-8 font-inter font-normal text-faint text-md md:text-lg lg:text-xl tracking-wide">
              At BlanqChequez Exchange, we trade all kinds of giftcards for the United States,
              United Kingdom, Australia and Canada. We are committed to providing you with swift payments, as our services
              are swift and trust worthy.
            </p>
          </section>
        </section>
      </section>

      {/*Logo Section*/}
      <section>
        <section className="px-12 sm:px-20 md:px-24 lg:px-48 py-16 lg:py-32 flex justify-center items-center">
          <svg className={`fill-current ${'text-maroon'} w-64 h-64 lg:w-64 lg:h-auto`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.834 197.033">
            <g id="logo" transform="translate(-114.269 -14.149)">
              <path id="Path_983" data-name="Path 983" d="M1064.06,461.692V451.37h3.486a4.66,4.66,0,0,1,1.867.282,2.162,2.162,0,0,1,.95.838,2.193,2.193,0,0,1,.344,1.174,2.423,2.423,0,0,1-.54,1.489,3.811,3.811,0,0,1-1.931,1.146,5.238,5.238,0,0,1,1.694.676,2.787,2.787,0,0,1,.891.963,2.476,2.476,0,0,1,.3,1.2,2.529,2.529,0,0,1-.357,1.3,2.427,2.427,0,0,1-.982.927,4.311,4.311,0,0,1-1.921.324Zm2.064-6.025h.459a1.972,1.972,0,0,0,1.457-.486,1.607,1.607,0,0,0,.483-1.18,1.362,1.362,0,0,0-.144-.642.939.939,0,0,0-.5-.422,4.092,4.092,0,0,0-1.372-.15h-.384Zm0,4.56h.411a5.22,5.22,0,0,0,1.526-.147,1.152,1.152,0,0,0,.612-.474,1.325,1.325,0,0,0,.207-.725,1.606,1.606,0,0,0-.677-1.3,2.659,2.659,0,0,0-1.71-.535h-.37Z" transform="translate(-771.322 -355.066)" fill="current"/>
              <path id="Path_984" data-name="Path 984" d="M1066.178,460.393h-4.2V449.288h3.878a4.974,4.974,0,0,1,2.031.318,2.55,2.55,0,0,1,1.119.988,2.591,2.591,0,0,1,.4,1.379,2.819,2.819,0,0,1-.62,1.728,3.165,3.165,0,0,1-1.089.862,4.01,4.01,0,0,1,.762.4,3.164,3.164,0,0,1,1.014,1.1,2.879,2.879,0,0,1,.35,1.385,2.935,2.935,0,0,1-.41,1.5,2.8,2.8,0,0,1-1.139,1.073A4.59,4.59,0,0,1,1066.178,460.393Zm-3.415-.783h3.415a4.039,4.039,0,0,0,1.74-.278,2.041,2.041,0,0,0,.826-.78,2.135,2.135,0,0,0,.3-1.1,2.079,2.079,0,0,0-.255-1.012,2.39,2.39,0,0,0-.77-.829,4.875,4.875,0,0,0-1.564-.618l-1.356-.33,1.332-.422a3.457,3.457,0,0,0,1.737-1.012,2.035,2.035,0,0,0,.46-1.251,1.8,1.8,0,0,0-.286-.97,1.766,1.766,0,0,0-.781-.688,4.368,4.368,0,0,0-1.7-.244h-3.095Zm2.084-.682h-.8v-3.963h.762a3.044,3.044,0,0,1,1.953.62,1.982,1.982,0,0,1,.824,1.606,1.729,1.729,0,0,1-.268.936,1.541,1.541,0,0,1-.809.63A5.35,5.35,0,0,1,1064.848,458.928Zm-.02-.783h.02a4.8,4.8,0,0,0,1.393-.122.772.772,0,0,0,.413-.315.93.93,0,0,0,.147-.517,1.227,1.227,0,0,0-.528-.994,2.247,2.247,0,0,0-1.445-.449Zm.067-3.777h-.85V450.7h.775a4.319,4.319,0,0,1,1.525.18,1.326,1.326,0,0,1,.694.6,1.755,1.755,0,0,1,.188.823,1.989,1.989,0,0,1-.6,1.456,2.344,2.344,0,0,1-1.734.6Zm-.067-.783h.067a1.626,1.626,0,0,0,1.18-.37,1.23,1.23,0,0,0,.369-.905.967.967,0,0,0-.1-.459.566.566,0,0,0-.3-.244,3.576,3.576,0,0,0-1.214-.119Z" transform="translate(-769.634 -353.375)" fill="current"/>
              <path id="Path_985" data-name="Path 985" d="M1123.35,461.692V451.37h2.148v8.857h4.6v1.466Z" transform="translate(-819.471 -355.066)" fill="current"/>
              <path id="Path_986" data-name="Path 986" d="M1128.792,460.393h-7.526V449.288h2.931v8.857h4.595Zm-6.743-.783h5.96v-.682h-4.595v-8.857h-1.366Z" transform="translate(-817.779 -353.375)" fill="current"/>
              <path id="Path_987" data-name="Path 987" d="M1172.94,461.692l4.02-10.322h2.153l4.017,10.322h-2.208l-1.071-2.755h-4.274l-1.072,2.755Zm3.171-4.129h3.2l-1.6-4.116Z" transform="translate(-859.743 -355.066)" fill="current"/>
              <path id="Path_988" data-name="Path 988" d="M1181.227,460.393h-3.048l-1.071-2.756h-3.739l-1.072,2.756h-2.4l4.324-11.105h2.688Zm-2.512-.783h1.368l-3.713-9.539h-1.618l-3.716,9.539h.725l1.071-2.755h4.809Zm-1.3-3.346h-4.347l2.174-5.587Zm-3.2-.783h2.06l-1.029-2.646Z" transform="translate(-857.269 -353.375)" fill="current"/>
              <path id="Path_989" data-name="Path 989" d="M1246.33,461.692V451.37h1.893l4.774,7.049v-7.048h1.723v10.321h-1.927l-4.74-7.052v7.052Z" transform="translate(-919.343 -355.066)" fill="current"/>
              <path id="Path_990" data-name="Path 990" d="M1253.424,460.393H1250.9l-4.142-6.159v6.159h-2.5V449.288h2.492l4.175,6.165v-6.165h2.506Zm-2.11-.783h1.327v-9.539h-.94V458l-5.374-7.933h-1.294v9.539h.939v-7.945Z" transform="translate(-917.655 -353.375)" fill="current"/>
              <path id="Path_991" data-name="Path 991" d="M1323.172,462.637q-1.138-.3-1.849-.547t-1.3-.523a11.932,11.932,0,0,1-1.367-.77c-.339.025-.59.034-.754.034a5.551,5.551,0,0,1-2.7-.658,4.322,4.322,0,0,1-1.838-1.918,6.783,6.783,0,0,1,0-5.676,4.355,4.355,0,0,1,1.821-1.921,6.186,6.186,0,0,1,5.593,0,4.317,4.317,0,0,1,1.82,1.927,6.394,6.394,0,0,1,.613,2.847,6.07,6.07,0,0,1-.687,2.936,4.662,4.662,0,0,1-2.067,1.966c.693.239,1.329.425,1.912.569a17.075,17.075,0,0,0,2.2.349Zm-5.2-3.223a2.424,2.424,0,0,0,2.217-1.2,5.713,5.713,0,0,0,0-5.6,2.644,2.644,0,0,0-4.442,0,5.272,5.272,0,0,0-.733,2.789,5.327,5.327,0,0,0,.728,2.789,2.421,2.421,0,0,0,2.23,1.218Z" transform="translate(-973.272 -353.946)" fill="current"/>
              <path id="Path_992" data-name="Path 992" d="M1321.6,461.378l-.215-.055c-.752-.2-1.385-.385-1.878-.557s-.939-.352-1.341-.538a12.154,12.154,0,0,1-1.3-.725c-.288.019-.5.025-.653.025a5.963,5.963,0,0,1-2.885-.707,4.707,4.707,0,0,1-2-2.086,7.169,7.169,0,0,1,0-6.025,4.76,4.76,0,0,1,1.982-2.089,6.576,6.576,0,0,1,5.973,0,4.715,4.715,0,0,1,1.982,2.1,6.816,6.816,0,0,1,.653,3.018,6.477,6.477,0,0,1-.734,3.123,4.929,4.929,0,0,1-1.477,1.672c.367.11.725.211,1.071.3a17.46,17.46,0,0,0,2.15.34l.823.082Zm-4.535-2.676.11.07a11.947,11.947,0,0,0,1.321.746c.378.174.8.346,1.268.507.427.15.965.312,1.6.48l.659-.651a14.139,14.139,0,0,1-1.444-.263c-.62-.153-1.275-.346-1.947-.578l-.876-.3.827-.416a4.311,4.311,0,0,0,1.9-1.8,5.719,5.719,0,0,0,.639-2.749,6.047,6.047,0,0,0-.573-2.676,3.911,3.911,0,0,0-1.658-1.755,5.8,5.8,0,0,0-5.214,0,3.988,3.988,0,0,0-1.661,1.752,6.4,6.4,0,0,0,0,5.328,3.934,3.934,0,0,0,1.674,1.749,5.206,5.206,0,0,0,2.512.609c.157,0,.4-.009.726-.034Zm-.777-.59a2.8,2.8,0,0,1-2.566-1.407,6.084,6.084,0,0,1,.007-5.981,3.034,3.034,0,0,1,5.112,0,6.1,6.1,0,0,1,0,6,2.8,2.8,0,0,1-2.553,1.391Zm0-8.007a2.04,2.04,0,0,0-1.89,1.021,5.3,5.3,0,0,0,0,5.175,2.256,2.256,0,0,0,3.775.013,5.32,5.32,0,0,0,0-5.19,2.039,2.039,0,0,0-1.882-1.019Z" transform="translate(-971.582 -352.253)" fill="current"/>
              <path id="Path_993" data-name="Path 993" d="M1395.706,460.168a6.634,6.634,0,0,1-1.179.407,11.512,11.512,0,0,1-1.139.183,11.774,11.774,0,0,1-1.26.07,5.078,5.078,0,0,1-4.038-1.526,6.366,6.366,0,0,1,0-7.789,5.255,5.255,0,0,1,4.174-1.523,14.489,14.489,0,0,1,3.347.4v1.682c-.534-.187-.971-.321-1.311-.407a8.489,8.489,0,0,0-.967-.193,6.517,6.517,0,0,0-.91-.064,2.929,2.929,0,0,0-2.581,1.144,4.8,4.8,0,0,0-.8,2.832,4.349,4.349,0,0,0,.91,2.869,3.335,3.335,0,0,0,2.71,1.095,6.866,6.866,0,0,0,3.047-.762Z" transform="translate(-1033.384 -353.945)" fill="current"/>
              <path id="Path_994" data-name="Path 994" d="M1390.439,459.531a5.471,5.471,0,0,1-4.334-1.661,6.753,6.753,0,0,1,0-8.3,5.642,5.642,0,0,1,4.47-1.658,15.045,15.045,0,0,1,3.438.407l.3.073v2.541l-.52-.18c-.521-.183-.947-.315-1.278-.4a7.841,7.841,0,0,0-.925-.183,6.2,6.2,0,0,0-.855-.061,2.55,2.55,0,0,0-2.262.979,4.394,4.394,0,0,0-.729,2.605,3.979,3.979,0,0,0,.82,2.618,2.931,2.931,0,0,0,2.408.954,6.491,6.491,0,0,0,2.875-.722l.564-.278v2.471l-.236.1a6.716,6.716,0,0,1-1.251.432,11.275,11.275,0,0,1-1.179.19A12.154,12.154,0,0,1,1390.439,459.531Zm.133-10.838a4.916,4.916,0,0,0-3.88,1.388,5.979,5.979,0,0,0,0,7.276c.991,1.135,2.717,1.563,4.962,1.324.406-.049.774-.107,1.1-.177a5.493,5.493,0,0,0,.871-.284V457.5a7.024,7.024,0,0,1-2.656.547,3.717,3.717,0,0,1-3.011-1.235,4.743,4.743,0,0,1-1-3.119,5.216,5.216,0,0,1,.872-3.055,3.312,3.312,0,0,1,2.9-1.312,6.845,6.845,0,0,1,.965.067,9.369,9.369,0,0,1,1.011.2c.228.058.5.138.82.242v-.826a14.157,14.157,0,0,0-2.955-.318Z" transform="translate(-1031.694 -352.256)" fill="current"/>
              <path id="Path_995" data-name="Path 995" d="M1455.74,461.692V451.37h2.148v4.19h4.268v-4.19h2.148v10.322h-2.148v-4.706h-4.269v4.706Z" transform="translate(-1089.404 -355.066)" fill="current"/>
              <path id="Path_996" data-name="Path 996" d="M1463,460.393h-2.931v-4.707h-3.485v4.707h-2.931V449.288h2.931v4.19h3.485v-4.19H1463Zm-2.148-.783h1.365v-9.539h-1.365v4.19H1455.8v-4.19h-1.366v9.539h1.366V454.9h5.051Z" transform="translate(-1087.71 -353.375)" fill="current"/>
              <path id="Path_997" data-name="Path 997" d="M1526.45,461.692V451.37h6.612v1.422H1528.6v2.875h3.688v1.422H1528.6v3.138h4.792v1.466Z" transform="translate(-1146.828 -355.066)" fill="current"/>
              <path id="Path_998" data-name="Path 998" d="M1532.093,460.393h-7.723V449.288h7.395v2.205H1527.3v2.092h3.689v2.205H1527.3v2.355h4.792Zm-6.939-.783h6.156v-.682h-4.792v-3.921h3.688v-.639h-3.688v-3.658h4.464v-.639h-5.829Z" transform="translate(-1145.139 -353.375)" fill="current"/>
              <path id="Path_999" data-name="Path 999" d="M1590.523,462.637q-1.138-.3-1.849-.547t-1.3-.523a11.881,11.881,0,0,1-1.367-.77c-.34.025-.59.034-.754.034a5.551,5.551,0,0,1-2.7-.658,4.32,4.32,0,0,1-1.838-1.918,6.783,6.783,0,0,1,0-5.676,4.357,4.357,0,0,1,1.821-1.921,6.187,6.187,0,0,1,5.594,0,4.318,4.318,0,0,1,1.82,1.927,6.394,6.394,0,0,1,.613,2.847,6.068,6.068,0,0,1-.687,2.936,4.661,4.661,0,0,1-2.067,1.966c.693.239,1.329.425,1.911.569a17.078,17.078,0,0,0,2.2.349Zm-5.2-3.223a2.424,2.424,0,0,0,2.217-1.2,5.712,5.712,0,0,0,0-5.6,2.644,2.644,0,0,0-4.442,0,5.274,5.274,0,0,0-.733,2.789,5.329,5.329,0,0,0,.728,2.789,2.421,2.421,0,0,0,2.23,1.218Z" transform="translate(-1190.387 -353.946)" fill="current"/>
              <path id="Path_1000" data-name="Path 1000" d="M1588.949,461.383l-.216-.055c-.752-.2-1.385-.385-1.878-.557s-.939-.352-1.341-.538a12.162,12.162,0,0,1-1.3-.725c-.287.019-.5.025-.653.025a5.964,5.964,0,0,1-2.885-.705,4.709,4.709,0,0,1-2-2.086,7.168,7.168,0,0,1,0-6.025,4.76,4.76,0,0,1,1.982-2.089,6.576,6.576,0,0,1,5.973,0,4.715,4.715,0,0,1,1.982,2.1,6.812,6.812,0,0,1,.653,3.018,6.478,6.478,0,0,1-.734,3.123,4.929,4.929,0,0,1-1.477,1.672c.367.11.725.211,1.071.3a17.446,17.446,0,0,0,2.15.34l.823.082Zm-4.535-2.676.11.07a11.908,11.908,0,0,0,1.321.746c.378.174.8.346,1.268.507.427.15.965.312,1.6.48l.659-.651a14.133,14.133,0,0,1-1.443-.263c-.62-.153-1.275-.346-1.947-.578l-.876-.3.827-.416a4.311,4.311,0,0,0,1.9-1.8,5.72,5.72,0,0,0,.639-2.749,6.044,6.044,0,0,0-.573-2.676,3.912,3.912,0,0,0-1.657-1.755,5.8,5.8,0,0,0-5.214,0,3.986,3.986,0,0,0-1.661,1.752,6.4,6.4,0,0,0,0,5.328,3.935,3.935,0,0,0,1.674,1.749,5.205,5.205,0,0,0,2.513.609c.157,0,.4-.009.726-.034Zm-.777-.59a2.8,2.8,0,0,1-2.566-1.407,6.084,6.084,0,0,1,.006-5.982,3.034,3.034,0,0,1,5.112,0,6.1,6.1,0,0,1,0,6,2.8,2.8,0,0,1-2.552,1.392Zm0-8.007a2.041,2.041,0,0,0-1.89,1.021,5.3,5.3,0,0,0,0,5.175,2.255,2.255,0,0,0,3.775.013,5.321,5.321,0,0,0,0-5.19,2.035,2.035,0,0,0-1.882-1.019Z" transform="translate(-1188.697 -352.258)" fill="current"/>
              <path id="Path_1001" data-name="Path 1001" d="M1657.079,451.374h2.148v6.33a3.217,3.217,0,0,0,.582,2.276,2.086,2.086,0,0,0,1.509.557,1.942,1.942,0,0,0,1.454-.551,3.086,3.086,0,0,0,.541-2.129v-6.483h1.868v6.34a5.409,5.409,0,0,1-.449,2.569,3.136,3.136,0,0,1-1.3,1.223,4.589,4.589,0,0,1-2.155.446,5.351,5.351,0,0,1-2.2-.413,3.349,3.349,0,0,1-1.459-1.235,4.9,4.9,0,0,1-.537-2.612Z" transform="translate(-1252.911 -355.069)" fill="current"/>
              <path id="Path_1002" data-name="Path 1002" d="M1659.59,460.652a5.766,5.766,0,0,1-2.364-.447,3.741,3.741,0,0,1-1.625-1.379A5.19,5.19,0,0,1,1655,456v-6.71h2.931v6.722c0,1.281.251,1.789.462,1.994a1.7,1.7,0,0,0,1.239.446,1.546,1.546,0,0,0,1.174-.434c.2-.2.43-.682.43-1.853V449.29h2.652v6.731a5.669,5.669,0,0,1-.5,2.765,3.514,3.514,0,0,1-1.457,1.373A4.974,4.974,0,0,1,1659.59,460.652Zm-3.807-10.579V456a4.588,4.588,0,0,0,.473,2.4,2.92,2.92,0,0,0,1.294,1.092,4.985,4.985,0,0,0,2.041.379,4.231,4.231,0,0,0,1.971-.4,2.743,2.743,0,0,0,1.145-1.074,5.093,5.093,0,0,0,.4-2.373v-5.948h-1.086v6.092a3.4,3.4,0,0,1-.653,2.4,2.325,2.325,0,0,1-1.734.667,2.464,2.464,0,0,1-1.78-.667,3.5,3.5,0,0,1-.7-2.557v-5.939Z" transform="translate(-1251.222 -353.377)" fill="current"/>
              <path id="Path_1003" data-name="Path 1003" d="M1724.9,461.692V451.37h6.612v1.422h-4.464v2.875h3.688v1.422h-3.688v3.138h4.792v1.466Z" transform="translate(-1307.989 -355.066)" fill="current"/>
              <path id="Path_1004" data-name="Path 1004" d="M1730.543,460.393h-7.723V449.288h7.395v2.205h-4.464v2.092h3.688v2.205h-3.688v2.355h4.791Zm-6.939-.783h6.156v-.682h-4.792v-3.921h3.688v-.639h-3.688v-3.658h4.464v-.639H1723.6Z" transform="translate(-1306.299 -353.375)" fill="current"/>
              <path id="Path_1005" data-name="Path 1005" d="M1779.02,461.688v-1.466l5.133-7.435h-4.84v-1.422h7.337v1.422l-5.132,7.435h5.132v1.466Z" transform="translate(-1351.939 -355.063)" fill="current"/>
              <path id="Path_1006" data-name="Path 1006" d="M1785.349,460.395h-8.413v-1.979l.069-.1,4.71-6.821h-4.485v-2.2h8.12v1.935l-4.777,6.921h4.777Zm-7.63-.783h6.847v-.682h-5.487l5.487-7.948v-.908h-6.554v.639h5.194l-5.488,7.948Z" transform="translate(-1350.247 -353.377)" fill="current"/>
              <path id="Path_1007" data-name="Path 1007" d="M1064.06,552.921V542.6h6.612v1.422h-4.464V546.9h3.688v1.422h-3.688v3.138H1071v1.466Z" transform="translate(-771.322 -429.153)" fill="current"/>
              <path id="Path_1008" data-name="Path 1008" d="M1069.693,551.618h-7.723V540.513h7.395v2.205H1064.9v2.092h3.688v2.205H1064.9v2.355h4.792Zm-6.939-.783h6.156v-.682h-4.792v-3.921h3.688v-.639h-3.688v-3.658h4.463V541.3h-5.829Z" transform="translate(-769.625 -427.459)" fill="current"/>
              <path id="Path_1009" data-name="Path 1009" d="M1151.82,552.921l3.307-5.083-3.16-5.239h2.5l2.1,3.48,2.276-3.48h1.737l-3.173,4.872,3.285,5.449h-2.5l-2.236-3.7-2.4,3.7Z" transform="translate(-842.592 -429.153)" fill="current"/>
              <path id="Path_1010" data-name="Path 1010" d="M1158.26,551.621h-3.41l-2.028-3.358-2.18,3.358h-2.67l3.568-5.484-3.392-5.621h3.42l1.888,3.135,2.051-3.135h2.67l-3.433,5.272Zm-2.969-.783h1.583l-3.052-5.068,2.913-4.471h-.8l-2.5,3.826-2.3-3.826h-1.59l2.93,4.857-3.048,4.682h.8l2.629-4.049Z" transform="translate(-839.468 -427.461)" fill="current"/>
              <path id="Path_1011" data-name="Path 1011" d="M1260.22,551.394a6.632,6.632,0,0,1-1.179.407,11.53,11.53,0,0,1-1.139.183,11.79,11.79,0,0,1-1.259.07,5.078,5.078,0,0,1-4.038-1.526,6.367,6.367,0,0,1,0-7.789,5.256,5.256,0,0,1,4.175-1.523,14.5,14.5,0,0,1,3.347.4V543.3c-.534-.187-.971-.321-1.31-.407a8.479,8.479,0,0,0-.966-.193,6.539,6.539,0,0,0-.91-.064,2.929,2.929,0,0,0-2.581,1.144,4.81,4.81,0,0,0-.8,2.832,4.348,4.348,0,0,0,.91,2.868,3.335,3.335,0,0,0,2.71,1.095,6.866,6.866,0,0,0,3.047-.762Z" transform="translate(-923.357 -428.029)" fill="current"/>
              <path id="Path_1012" data-name="Path 1012" d="M1254.939,550.755a5.471,5.471,0,0,1-4.334-1.661,6.754,6.754,0,0,1,0-8.3,5.642,5.642,0,0,1,4.47-1.658,15.056,15.056,0,0,1,3.438.407l.3.073v2.541l-.52-.181c-.521-.183-.946-.315-1.278-.4a7.819,7.819,0,0,0-.925-.184,6.188,6.188,0,0,0-.855-.061,2.551,2.551,0,0,0-2.262.979,4.394,4.394,0,0,0-.729,2.606,3.98,3.98,0,0,0,.819,2.618,2.931,2.931,0,0,0,2.409.954,6.494,6.494,0,0,0,2.875-.722l.564-.278v2.471l-.236.1a6.713,6.713,0,0,1-1.251.432,11.267,11.267,0,0,1-1.179.19A12.1,12.1,0,0,1,1254.939,550.755Zm.133-10.838a4.916,4.916,0,0,0-3.879,1.388,5.979,5.979,0,0,0,0,7.276c.991,1.135,2.719,1.563,4.962,1.324.406-.049.773-.107,1.1-.177a5.5,5.5,0,0,0,.872-.284v-.719a7.023,7.023,0,0,1-2.656.547,3.716,3.716,0,0,1-3.011-1.235,4.743,4.743,0,0,1-1-3.119,5.215,5.215,0,0,1,.872-3.055,3.313,3.313,0,0,1,2.9-1.312,6.852,6.852,0,0,1,.965.067,9.369,9.369,0,0,1,1.011.2c.228.058.5.138.82.242v-.826a14.155,14.155,0,0,0-2.956-.318Z" transform="translate(-921.655 -426.339)" fill="current"/>
              <path id="Path_1013" data-name="Path 1013" d="M1355.97,552.921V542.6h2.148v4.19h4.268V542.6h2.148v10.322h-2.148v-4.707h-4.268v4.707Z" transform="translate(-1008.381 -429.153)" fill="current"/>
              <path id="Path_1014" data-name="Path 1014" d="M1363.231,551.618H1360.3v-4.707h-3.485v4.707h-2.931V540.513h2.931v4.19h3.485v-4.19h2.931Zm-2.148-.783h1.365V541.3h-1.365v4.19h-5.051V541.3h-1.366v9.539h1.366v-4.706h5.051Z" transform="translate(-1006.688 -427.459)" fill="current"/>
              <path id="Path_1015" data-name="Path 1015" d="M1456.4,552.921l4.02-10.322h2.153l4.017,10.322h-2.208l-1.071-2.755h-4.274l-1.071,2.755Zm3.172-4.129h3.2l-1.6-4.116Z" transform="translate(-1089.94 -429.153)" fill="current"/>
              <path id="Path_1016" data-name="Path 1016" d="M1464.693,551.618h-3.047l-1.071-2.756h-3.739l-1.071,2.756h-2.4l4.324-11.105h2.688Zm-2.512-.783h1.369l-3.713-9.539h-1.618l-3.716,9.539h.725l1.071-2.755h4.809Zm-1.3-3.346h-4.347l2.174-5.588Zm-3.2-.783h2.06l-1.03-2.645Z" transform="translate(-1087.472 -427.459)" fill="current"/>
              <path id="Path_1017" data-name="Path 1017" d="M1565.52,552.921V542.6h1.893l4.774,7.049V542.6h1.723v10.322h-1.927l-4.742-7.052v7.052Z" transform="translate(-1178.556 -429.153)" fill="current"/>
              <path id="Path_1018" data-name="Path 1018" d="M1572.6,551.618h-2.526l-4.143-6.159v6.159h-2.5V540.513h2.493l4.175,6.165v-6.165h2.506Zm-2.11-.783h1.327V541.3h-.94v7.933l-5.374-7.933h-1.293v9.539h.94V542.89Z" transform="translate(-1176.86 -427.459)" fill="current"/>
              <path id="Path_1019" data-name="Path 1019" d="M1676.536,551.553a16.689,16.689,0,0,1-2.012.428,11.57,11.57,0,0,1-1.334.073,5.278,5.278,0,0,1-4.22-1.535,5.792,5.792,0,0,1-1.318-3.9,6.433,6.433,0,0,1,.58-2.774,4.345,4.345,0,0,1,1.754-1.93,6.2,6.2,0,0,1,3.184-.7,14.542,14.542,0,0,1,3.361.4V543.3a13.106,13.106,0,0,0-2.015-.572,7.911,7.911,0,0,0-1.171-.092,2.93,2.93,0,0,0-2.612,1.171,4.929,4.929,0,0,0-.8,2.841,4.247,4.247,0,0,0,.94,2.894,3.342,3.342,0,0,0,2.651,1.073,4.211,4.211,0,0,0,.864-.1v-3.4h2.149Z" transform="translate(-1261.496 -428.029)" fill="current"/>
              <path id="Path_1020" data-name="Path 1020" d="M1671.488,550.753a5.664,5.664,0,0,1-4.517-1.672,6.191,6.191,0,0,1-1.413-4.15,6.86,6.86,0,0,1,.616-2.939,4.739,4.739,0,0,1,1.909-2.1,6.575,6.575,0,0,1,3.384-.759,15.091,15.091,0,0,1,3.451.407l.3.073v2.539l-.518-.177a13,13,0,0,0-1.947-.553,7.528,7.528,0,0,0-1.113-.089,2.55,2.55,0,0,0-2.289,1,4.579,4.579,0,0,0-.729,2.621,3.881,3.881,0,0,0,.844,2.636,2.951,2.951,0,0,0,2.356.94,3.026,3.026,0,0,0,.473-.04v-3.463h2.931v5.138l-.294.077a16.861,16.861,0,0,1-2.065.437A11.67,11.67,0,0,1,1671.488,550.753Zm-.021-10.838a5.832,5.832,0,0,0-2.983.648,3.973,3.973,0,0,0-1.6,1.759,6.082,6.082,0,0,0-.543,2.609,5.43,5.43,0,0,0,1.225,3.642,4.935,4.935,0,0,0,3.922,1.4,11.484,11.484,0,0,0,1.289-.07,15.4,15.4,0,0,0,1.667-.343v-3.749h-1.366v3.324l-.31.064a4.48,4.48,0,0,1-.945.113,3.727,3.727,0,0,1-2.945-1.208,4.62,4.62,0,0,1-1.038-3.15,5.342,5.342,0,0,1,.865-3.061,3.317,3.317,0,0,1,2.936-1.343,8.678,8.678,0,0,1,1.229.094,10.684,10.684,0,0,1,1.566.416v-.826a14.212,14.212,0,0,0-2.969-.317Z" transform="translate(-1259.797 -426.337)" fill="current"/>
              <path id="Path_1021" data-name="Path 1021" d="M1774.88,552.921V542.6h6.612v1.422h-4.463V546.9h3.688v1.422h-3.688v3.138h4.792v1.466Z" transform="translate(-1348.577 -429.153)" fill="current"/>
              <path id="Path_1022" data-name="Path 1022" d="M1780.513,551.618h-7.723V540.513h7.395v2.205h-4.462v2.092h3.688v2.205h-3.688v2.355h4.792Zm-6.939-.783h6.156v-.682h-4.792v-3.921h3.688v-.639h-3.688v-3.658h4.464V541.3h-5.829Z" transform="translate(-1346.88 -427.459)" fill="current"/>
              <path id="Path_1023" data-name="Path 1023" d="M139.269,121.84H199.3q32.4,0,46.267,11.371,11.221,9.156,11.222,26.692a29.746,29.746,0,0,1-10.27,23.225q-8.789,7.9-23.186,10.12V194.6q19.9,2.991,29.963,13.78,8.679,9.349,8.681,23.13,0,21.877-17.469,33.341-15.565,10.12-42.138,10.12h-63.1Zm28.587,19.946v42.981h25.622q18,0,26.891-6.841a18.4,18.4,0,0,0,7.412-15.42q0-11.276-8.152-16.19-7.519-4.527-23.611-4.529Zm0,62.93v50.3h31.232q17.574,0,26.151-7.612,7.2-6.454,7.2-17.827,0-12.528-11.011-19.083-9.743-5.78-26.046-5.78Z" transform="translate(-20.302 -87.456)" fill="current"/>
              <path id="Path_1024" data-name="Path 1024" d="M182.069,259.362h-67.8V96.84H179c22.928,0,39.036,4.068,49.244,12.435,8.586,7.007,12.942,17.212,12.942,30.326a34.4,34.4,0,0,1-11.841,26.732,42.068,42.068,0,0,1-10.852,7.013,45.993,45.993,0,0,1,17.94,11.527c6.6,7.107,9.942,15.964,9.942,26.332,0,16.2-6.591,28.738-19.59,37.268C215.664,255.7,200.626,259.362,182.069,259.362Zm-58.4-9.4h58.4c16.709,0,30.024-3.15,39.578-9.361,10.311-6.764,15.331-16.385,15.331-29.4,0-8.031-2.43-14.551-7.425-19.934-5.929-6.358-15.089-10.508-27.219-12.331l-4-.6v-9.422l3.984-.612c8.634-1.33,15.618-4.346,20.76-8.97A25.051,25.051,0,0,0,231.79,139.6c0-10.3-3.106-17.839-9.494-23.053-8.352-6.844-22.917-10.312-43.3-10.312H123.664Zm55.122-10.553h-35.93v-59.7H175.08c11.672,0,21.241,2.165,28.442,6.438,8.711,5.186,13.313,13.181,13.313,23.123,0,8.936-2.948,16.111-8.762,21.325C201.483,236.452,191.635,239.413,178.786,239.413Zm-26.536-9.4h26.536c10.481,0,18.229-2.162,23.032-6.429,3.779-3.385,5.623-8.074,5.623-14.313,0-6.725-2.769-11.505-8.718-15.047-5.724-3.395-13.679-5.12-23.642-5.12H152.25Zm20.925-60.856H142.856V116.786h32.859c11.706,0,20.221,1.7,26.035,5.2,4.757,2.872,10.426,8.707,10.426,20.215,0,8-3.112,14.441-9.25,19.147-6.827,5.254-16.562,7.811-29.751,7.811Zm-20.925-9.4h20.925c10.884,0,18.969-1.973,24.026-5.866,3.809-2.921,5.581-6.636,5.581-11.7,0-5.881-1.869-9.747-5.881-12.169-4.245-2.557-11.374-3.854-21.185-3.854H152.25Z" transform="translate(0 -67.153)" fill="current"/>
              <path id="Path_1025" data-name="Path 1025" d="M814.028,153.609l-28.375,6.263q-6.248-32.378-35.361-32.378-20.646,0-31.868,16.768-10.379,15.418-10.377,42.015,0,30.263,13.447,45.969,11.115,12.913,28.8,12.912,30.065,0,37.054-32.763L814.98,220.1q-12.918,45.585-64.9,45.583-35.572,0-55.373-23.417-17.682-20.909-17.682-55.7,0-39.993,22.87-61.483,19.375-18.116,50.184-18.117,27.316,0,44.467,14.068Q809.157,133.087,814.028,153.609Z" transform="translate(-457.012 -75.379)" fill="current"/>
              <path id="Path_1026" data-name="Path 1026" d="M729.786,250.076c-25.052,0-44.887-8.437-58.96-25.081-12.469-14.747-18.791-34.506-18.791-58.734,0-27.882,8.193-49.721,24.35-64.905,13.765-12.872,31.729-19.393,53.4-19.393,19.238,0,35.2,5.092,47.446,15.132,10.514,8.673,17.6,20.487,21.073,35.121l1.1,4.645-37.745,8.331-.913-4.737c-3.762-19.493-13.533-28.57-30.749-28.57-12.207,0-21.354,4.8-27.965,14.683-6.361,9.45-9.583,22.7-9.583,39.4,0,18.928,4.144,33.366,12.319,42.914,6.523,7.581,14.779,11.27,25.229,11.27,17.921,0,28.235-9.23,32.462-29.044l1.041-4.89,36.991,10.319-1.286,4.538C789.993,233.59,766.636,250.076,729.786,250.076Zm0-158.717c-19.212,0-35.017,5.67-46.976,16.851-14.184,13.331-21.38,32.864-21.38,58.052,0,21.946,5.575,39.666,16.571,52.666,12.375,14.638,29.315,21.753,51.786,21.753,30.818,0,50.154-12.331,58.964-37.665l-18.307-5.1c-5.938,20.726-19.837,31.638-40.445,31.638-13.164,0-24.052-4.893-32.358-14.545-9.681-11.307-14.585-27.8-14.585-49.033,0-18.6,3.76-33.619,11.178-44.638,8.362-12.5,20.4-18.842,35.765-18.842,14.227,0,32.118,5.609,38.907,31.482L787.9,129.78a52.479,52.479,0,0,0-16.637-25.426c-10.509-8.621-24.469-12.995-41.478-12.995Z" transform="translate(-436.718 -55.072)" fill="current"/>
              <rect id="Rectangle_9" data-name="Rectangle 9" width="22.183" height="40.189" transform="translate(202.978 14.149)" fill="current"/>
              <rect id="Rectangle_10" data-name="Rectangle 10" width="22.444" height="40.189" transform="translate(262.48 14.149)" fill="current"/>
              <rect id="Rectangle_11" data-name="Rectangle 11" width="22.183" height="47.236" transform="translate(202.978 163.946)" fill="current"/>
              <rect id="Rectangle_12" data-name="Rectangle 12" width="22.444" height="47.236" transform="translate(262.48 163.946)" fill="current"/>
            </g>
          </svg>
        </section>
      </section>

      {/*Footer Section*/}
      <section className="bg-bottom-blob bg-center bg-cover bg-no-repeat py-32 pb-12 lg:py-24 lg:pb-12">
        <Footer inverse="true"/>
      </section>
    </section>
  )
}

export default About;