import React from 'react';
import Header from '../components/header/Header';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import topBlob from '../assets/top-blob.svg';
import topIllustration from '../assets/pana.svg';
import MobileNav from '../components/mobileNav/MobileNav';
import { ContextDestructured } from '../context/AppContext';
import transparent from '../assets/eye.svg';
import instant from '../assets/instant.svg';
import secure from '../assets/lock.svg';
import userOctagon from '../assets/user-octagon.svg';
import star from '../assets/ios-star.svg';
import amico from '../assets/amico.svg';
import checkMark from '../assets/ios-checkmark.svg';
import cuate from '../assets/cuate.svg';
import itunes from '../assets/itunes.svg';
import googleplay from '../assets/googleplay.svg';
import ebay from '../assets/ebay.svg';
import netflix from '../assets/netflix.svg';
import steam from '../assets/steam.svg';
import playstation from '../assets/playstation.svg';
import amazon from '../assets/amazon.svg';
import { Link } from 'react-router-dom';
import {useEffect} from 'react';
import Calculator from '../components/rates/Calculator';

const Home = () => {

    const {userName, darkMode, chatLink, calculatorVisible, setCalculatorVisible, setOpenNav} = ContextDestructured();
    useEffect(() => {setOpenNav(false)}, [])
    const reasons = [
        {
          imgSrc: instant,
          header: 'Instant',
          text: 'Using modernized payment procedures, you\'re guaranteed to get your payments sent to your account within minutes. We take great delight in our customer satisfaction, and as such we avoid take great measure to curb payment delays.'
        },
    
        {
          imgSrc: transparent,
          text: 'Using modernized payment procedures, you\'re guaranteed to get your payments sent to your account within minutes. We take great delight in our customer satisfaction, and as such we avoid take great measure to curb payment delays.',
          header: 'Transparent'
        },
    
        {
          imgSrc: secure,
          text: 'Using modernized payment procedures, you\'re guaranteed to get your payments sent to your account within minutes. We take great delight in our customer satisfaction, and as such we avoid take great measure to curb payment delays.',
          header: 'Secure'
        }
      ]
    
    const giftCards = [itunes, steam, ebay, amazon, googleplay]


  return (
    <div className="dark">
      <section className="bg-top-blob bg-cover bg-center bg-no-repeat">
        <Navbar inverse={true}/>
        <MobileNav/>
        <section className="px-12 sm:px-20 md:px-24 lg:px-48 py-16 lg:py-32">
          <section className="flex flex-col lg:flex-row md:space-y-12 lg:space-x-3">
            {/*Top Copy*/}
            <section className="text-white">
              <h1 className="text-3xl md:text-6xl lg:text-6xl max-w-3xl font-extrabold font-inter">BlanqChequez Exchange</h1>
              <p className="mt-8 text-md font-medium w-full sm:text-lg md:text-2xl max-w-xl font-inter lg:font-light">The best and most trusted website to trade your unused gift cards and cryptocurrencies for instant cash. Trading has never been this easy</p>
              
              {/*Call To Actions*/}
              <section className="hidden lg:flex mt-8 flex-col lg:flex-row lg:space-x-6 space-y-6 lg:space-y-0 font-inter">
                <button className="rounded bg-white text-maroon px-6 py-3">
                  <a target="_blank" href={chatLink}>Instant Trade</a>
                </button>
                <button onClick = {ev => setCalculatorVisible(!calculatorVisible)} className="px-6 py-3 bg-transparent text-white border-white border rounded">View Rates</button>
              </section>
            </section>

            {/*Top Illustration*/}
            <section className="mt-10 md:mt-0 flex justify-center items-center">
              <img className="w-full scale-110 translate-y-14 sm:w-4/5 lg:w-4/5 lg:translate-x-16 lg:-translate-y-8" src={topIllustration} alt="top illustration" />
            </section>
          </section>
        </section>
      </section>

      <Calculator/>

      {/* CTAs Hidden On Larger Screens*/}
      <section className="px-12 sm:px-20 py-32 lg:hidden">
        <section className="flex flex-col justify-center items-center gap-3 font-inter font-medium">
          <button className="px-6 py-4 rounded bg-maroon border transition duration-300 text-white w-full max-w-2xl hover:bg-white hover:text-maroon hover:border-maroon">
            <a target="_blank" href={chatLink}>Instant Trade</a>
          </button>
          <button onClick = {ev => setCalculatorVisible(!calculatorVisible)} className="px-6 py-4 rounded bg-transparent transition duration-300 text-maroon border border-maroon w-full max-w-2xl hover:bg-maroon hover:text-white hover:border-transparent">View Rates</button>
        </section>
      </section>

      {/*Why Trade With Us Section*/}
      <section className="px-12 sm:px-20 md:px-24 lg:px-32 py-12 sm:py-16 md:py-20 lg:py-28">
        <section className="flex justify-center items-center">
          <h2 className="font-sans font-bold uppercase text-3xl md:text-4xl lg:text-4xl xl:text-6xl text-maroon">Why Choose Us ?</h2>
        </section>
        <section>
          <ul className="py-24 flex flex-col lg:flex-row space-y-8 lg:space-x-4 xl:space-x-8 lg:space-y-0">
            {reasons.map((reason, index) => 
              <li key={index} className="py-6 bg-secondary rounded-md">
                <section className="px-12 ">
                  <section className=" rounded w-8 h-8 bg-maroon p-1 flex items-center justify-center">
                    <img className="w-8 h-8" src={reason.imgSrc} alt={reason.header} />
                  </section>
                  
                  <h3 className="mt-6 text-xl uppercase font-bold font-sans text-maroon">{reason.header}</h3>
                  <p className="text-md leading-6 mt-6 font-inter font-light">{reason.text}</p>
                </section>
              </li>
            )}
          </ul>
        </section>
      </section>

      {/*Customer Stats*/}
      <section className="space-y-12 md:sapce-y-0 sm:space-y-0 flex flex-col sm:flex-row px-8 sm:px-12 md:px-20 lg:px-32 sm:space-x-6 md:space-x-16 xl:space-x-24 justify-center items-center py-12 sm:py-16 md:py-20">
        <section>
          <h1 className="text-maroon text-3xl md:text-6xl text-center font-sans font-bold">30K</h1>
          <section className="flex space-x-3 mt-3 items-center">
            <img className="w-6 h-6 md:w-8 md:h-8" src={userOctagon} alt="" />
            <p className="capitalize text-xl font-sans text-faint">Happy Clients</p>
          </section>
        </section>

        <section className="pl-0 lg:pl-14 lg:border-l border-faint">
          <h1 className="text-maroon text-3xl md:text-6xl text-center font-sans font-bold">130K</h1>
          <section className="flex space-x-3 mt-3 items-center">
            <img className="w-6 h-6 md:w-8 md:h-8" src={checkMark} alt="" />
            <p className="capitalize text-xl font-sans text-faint">Transactions</p>
          </section>
        </section>

        <section className="pl-0 lg:pl-8 lg:border-l border-faint">
          <h1 className="text-maroon text-3xl md:text-6xl text-center font-sans font-bold">10K</h1>
          <section className="flex space-x-3 mt-3 items-center">
            <img className="w-6 h-6 md:w-8 md:h-8" src={star} alt="" />
            <p className="capitalize text-xl font-sans text-faint">Client Ratings</p>
          </section>
        </section>
      </section>

      {/*Trade With Us*/}
      <section className="px-12 sm:px-20 md:px-24 lg:px-32 py-16 md:py-20 lg:py-32 flex flex-col-reverse lg:flex-row space-y-8 lg:space-y-0 space-x-0 lg:space-x-8">
        <section className="mt-6 lg:mt-0">
          <h3 className="sm:text-center md:text-left text-maroon text-2xl md:text-3xl lg:text-4xl font-bold font-sans mt-6 lg:mt-0">Trade Anywhere and Anytime!</h3>
          <section className="mt-6 sm:flex sm:flex-col md:block items-center">
            <p className="w-full md:w-4/5 lg:w-3/5 leading-7 font-inter text-md sm:text-lg md:font-xl font-light">BlanqChequez Exchange is your number one source for trading your cryptocurrencies and unused giftcards. We're committed to
              providing you with the very best of trading, with emphasis on integrity, passion and professionalism.
            </p>
            <p className="w-full md:w-4/5 lg:w-3/5 leading-7 font-inter text-md sm:text-lg md:font-xl font-light mt-4">
              With BlanqChequez Exchange, you can trade your giftcards, sell your cryptocurrencies and other electronic assets on the go, we've got you covered, and you are simply a click away.</p>
              <section className="mt-6 flex items-center py-8">
                <button className="bg-maroon text-white rounded font-inter font-light text-md sm:text-lg md:text-xl px-6 py-3">Learn More</button>
              </section>
          </section>
        </section>
        <section>
          <section className="flex items-center justify-center">
            <img className="w-full lg:-translate-x-16 lg:translate-y-32 lg:scale-150 xl:translate-y-0 xl:-translate-x-32 xl:scale-150" src={amico} alt="trade now" />
          </section>
        </section>
      </section>

      {/*Gift Cards Sections*/}
      <section className="px-12 sm:px-20 md:px-24 lg:px-32 py-12 sm:py-16 md:py-20 lg:py-28">
        <section className="py-12 flex justify-center items-center">
          <h2 className="text-2xl md:text-4xl lg:6xl font-sans font-bold text-maroon capitalize">Some of the Giftcards we buy</h2>
        </section>
        <section className="flex justify-center flex-wrap gap-12 mt-6">
          {giftCards.map((giftCard, index) => (
            <img key={index} className="w-20 md:w-32 xl:w-48" src={giftCard} alt={`giftCard ${index}`}/>
          ))}
        </section>
      </section>


      {/*Testimonials */}

      {/*Last Illustration Section*/}
      <section className="px-12 sm:px-20 md:px-24 lg:px-32 py-16 md:py-20 lg:py-32 flex flex-col">
        <section className="flex justify-center items-center">
          <img className="w-full md:w-3/5 lg:w-2/5" src={cuate} alt="last illustration" />
        </section>
        <section className="py-16 flex flex-col items-center justify-center">
          <h2 className="font-inter font-bold text-2xl lg:text-4xl">Start Trading Today!</h2>
          <p className="mt-6 font-inter font-light text-lg lg:text-xl">Our platform is developed specifically for easy and seamless tradings</p>
          <section className="flex items-center justify-center mt-12">
            <button className="font-inter font-medium text-md lg:text-lg rounded px-8 py-3 bg-maroon text-white"><a href={chatLink} className="">Trade Now</a></button>
          </section>
        </section>
      </section>

      {/*Footer*/}
      <section className="bg-bottom-blob bg-center bg-cover bg-no-repeat py-32 pb-12 lg:py-24 lg:pb-12">
        <Footer inverse="true"/>
      </section>
    </div>
  )
}

export default Home;