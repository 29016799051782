import React from 'react'
import Navbar from '../components/navbar/Navbar';
import MobileNav from '../components/mobileNav/MobileNav';
import Footer from '../components/footer/Footer';
import Faq from '../components/faq/Faq';
import {useEffect} from 'react';
import { ContextDestructured } from '../context/AppContext';
import Calculator from '../components/rates/Calculator';


const FAQs = () => {

  const {setOpenNav} = ContextDestructured();
  useEffect(() => {setOpenNav(false)}, [])

  const questions = [
    {
      question: 'What Gift Card Can I Sell On Blanqchequez Exchange?',
      answer: 'We accept the following gift cards: iTunes Gift Cards, Amazon Gift Cards, Steam Wallet, Walmart Gift Cards, Apple Store Gift Cards, Google Play Gift Cards, eBay Gift Cards, Vanilla Gift Cards, Sephora Gift Cards, and many more... Please note that not all cards will be available for trade at all times. For more information, talk us via our customer support phone number.'
    },

    {
      question: 'What Country Gift Cards Are Acceptable Or Unacceptable On Cardtonic?',
      answer: 'We buoyantly accept cards from: USA, UK, any European country, Canada, Australia, Switzerland, New Zealand, Singapore and many more. We do not accept cards from: Mexico, China, Japan, Philippines, or any African countries.'
    },

    {
      question: 'What Is The Difference Between eCode, Physical Cards And Printable Vouchers?',
      answer: 'Physical gift cards are actual physical (plastic) cards that are purchased directly from a physical store. Physical gift cards are safest and will fetch you the best money return. Ecodes are digital gift card codes purchased online and mostly sent to the buyer’s email address. Ecodes are not as safe as physical gift cards thereby the money value is always lower. On the other hand, printable vouchers also known as paper codes are somewhat in between physical gift cards and ecodes. It is similar to an e-gift code because only code is purchased and also similar to physical gift cards because they can be printed physically like a receipt.'
    },

    {
      question: 'Card Already Redeemed, What Do I Do?',
      answer: 'This means your card has been previously used before you sent it. Unfortunately, there’s nothing BLANQCHEQUEZ EXCHANGE can do concerning this. You should contact the gift card provider for more details. In most cases, we provide screenshot of the error message, in some cases, we do not.'
    },

    {
      question: 'Can I Know The Date and Time My Gift Card Was Redeemed ?',
      answer: "No, we do not have access to this information. Kindly visit where you purchased it or contact the provider's customer service to get these information."
    },

    {
      question: 'Does Gift Card Expire?',
      answer: "Well some do, some don’t. Gift cards come with expiry dates, but certainly not all gift cards. It is pertinent you check the gift card and its packaging to know the expiry date. The recently implemented gift card rules indicate that the expiry date of gift cards should be clearly noted on the card. Inactivity fees are also required to be displayed on the card/packaging and you can only be charged once in a month."
    },

    {
      question: 'Can I Buy Gift Cards On BLANQCHEQUEZ EXCHANGE?',
      answer: "It is not currently possible as at this time. But, we are making provision for that. Kindly check back later."
    },

    {
      question: "Are Gift Card Rates Constant?",
      answer: "Gift cards continuously undergo variations in prices. We advise that you check our rate calculator page either on your mobile app or on the website dashboard to verify current gift card rates. Check updated gift card rates using our rate calculator here. The gift card rate calculator is available on our website. It is frequently updated and any rate seen there is up-to-date. If you have further questions you can contact our support team at 08066600343."
    },

    {
      question: "What Gift Card Has The Highest Rate At The Moment?",
      answer: "This is a rather rhetorical question as no specific or general answer can be given.Gift card values and rates vary from time to time depending on several factors. So the gift card that has the best rate at this moment might be different in the next hour. We advise that you check real time value of gift cards using 'The Rate Calculator'. Be rest assured that BLANQCHEQUEZ EXCHANGE provides you with the best rates always."
    },

    {
      question: "How long does confirmations take?",
      answer: "We try as much as possible to be timely in card processing however network delays could affect confirmations but be sure we will do all our best to deliver on time."
    }
  ]

  return (
    <section>
      <section>
        <Navbar/>
        <MobileNav/>
      </section>

      <Calculator/>

      {/*FAQs Sections*/}
      <section id="faqs">
        <section className="px-12 sm:px-20 md:px-24 lg:px-48 py-16 lg:py-32">
          <h2 className="mb-16 font-bold font-inter text text-xl md:text-3xl lg:text-4xl text-maroon text-center">Frequently Asked Questions (FAQs)</h2>
          <section className="flex flex-col items-center space-y-8">
            {questions.map((question, index) => (
              <Faq key={question.question} question={question} />
            ))}
          </section>
        </section>
      </section>

      {/*Footer*/}
      <section className="bg-bottom-blob bg-center bg-cover bg-no-repeat py-32 pb-12 lg:py-24 lg:pb-12">
        <Footer inverse="true"/>
      </section>
    </section>
  )
}

export default FAQs;
